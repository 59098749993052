.author {
  display: flex;
  justify-content: space-between;
  margin: 1.2rem 3rem 0 1.2rem;
  color: white;
  &__details {
    display: flex;
    &__img {
      display: inline;
    }

    &__date {
      font-weight: 100;
    }
  }

  h4 {
    display: inline;
    font-size: 1.2rem;
    margin: 0rem;
  }

  .first {
    display: flex;
    flex-direction: row;
    // align-items: center;
    margin: 1.2rem 0;
    padding: 0.5rem;
  }
  &__follow {
    display: flex;
    margin: auto 2rem;
    flex-direction: column;
    .desc {
      font-size: 0.9rem;
      font-weight: 100;
      color: rgb(53, 45, 37);
    }
  }
}

.authorSimple {
  h4 {
    display: inline;
    text-transform: capitalize;
    color: rgb(63, 103, 156);
    font-size: 0.9rem;
  }
}
