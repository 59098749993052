.follow {
  margin: auto 0;
  background-color: rgb(94, 128, 28);
  color: white;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  // justify-content: center;
  border-radius: 0.75rem;
  cursor: pointer;

  span {
    margin: 0.5rem 2rem;
    font-weight: 600;
    letter-spacing: 0.1rem;
    text-align: center;
  }
}
.large {
  max-height: 2rem;
}

.small {
  display: inline;
  height: 2rem;
  margin-left: 1rem;
  background-color: rgba(102, 51, 153, 0.24);
  color: rgb(36, 34, 32);
  span {
    margin: auto 1rem;
    // padding: 1rem 0;
    font-size: 0.7rem;
    font-weight: 700;
  }
  &:hover {
    background-color: rebeccapurple;
    color: white;
  }
}
