@import '/src/styles/main.scss';

.contents {
    padding: 1rem 2.5rem;
    background-color: lighten($tertiary, 10%);
}

.card {
    padding: 0 0 0 0.8rem;
    background-color: lighten($tertiary, 20%);

    margin-bottom: 1rem;
    min-height: 160px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-radius: 10px;
    .featured {
        border-radius: 10px;
        max-height: 10rem;
    }
    h2 {
        font-size: 2rem;
        padding: 0.8rem 0;
    }
    h3 {
        font-size: 1rem;
    }
    span {
        font-size: 0.7rem;
        color: darken($tertiary, 50%);
    }

    &:hover {
        background-color: lighten($tertiary, 30%);
    }
}

.trend {
    background-color: $light;
    padding: 1rem 2rem;

    &__top {
        span {
            font-size: 1.5rem;
        }
        .icon {
            height: 20px !important;
            width: 2rem !important;
        }
    }

    &__card {
        margin: 0.8rem 2rem 0.5rem 0;
        width: 25rem;
        width: 100%;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;

        .trending-card {
            background-color: lighten($tertiary, 20%);
            border-radius: 5px;
            width: 32%;
            margin: 0.4rem;
            padding: 0.5rem;
            display: flex;
            flex-direction: column;
            align-items: stretch;

            &:hover {
                background-color: lighten($tertiary, 30%);
            }
        }

        h2 {
            font-size: 1.5rem;
            padding: 0.8rem 0;
            margin-bottom: 0px;
        }
        .blogCard__details {
            display: flex;
            gap: 1rem;
        }
        span {
            font-size: 0.7rem;
            color: darken($tertiary, 50%);
        }
    }
}
