@import '/src/styles/main.scss';

.nav {
    position: sticky;
    top: 0;
    background-color: $primary;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 1rem 2rem;

    &__logo {
        a > h2 {
            font-size: 2rem;
            color: $light;
            &:hover {
                color: $dark;
            }
        }
    }

    &__links {
        list-style-type: none;
        display: flex;
        align-items: center;

        li a {
            margin-left: 20px;
            margin-right: 20px;
            padding: 2px;

            color: $light;
            text-decoration: none;
            &:hover {
                color: lighten($color: $light, $amount: 10%);
            }
        }
    }

    .button_slide {
        color: $light;
        border: 2px solid $tertiary;
        margin-left: 20px;
        padding: 10px 20px;
        display: inline-block;
        font-family: 'Lucida Console', Monaco, monospace;
        font-size: 14px;
        cursor: pointer;
        box-shadow: inset 0 0 0 0 #d80286;
        -webkit-transition: ease-out 0.5s;
        -moz-transition: ease-out 0.5s;
        transition: ease-out 0.5s;
    }

    .slide_right:hover {
        box-shadow: inset 400px 0 0 0 #d80286;
    }

    .slide_in:hover {
        box-shadow: inset 0 0 0 50px #d80286;
    }

    /* Underline From Left */
    .hvr-underline-from-left {
        display: inline-block;
        padding-left: 0px 10px;
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        box-shadow: 0 0 1px rgba(0, 0, 0, 0);
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        -moz-osx-font-smoothing: grayscale;
        position: relative;
        overflow: hidden;
    }
    .hvr-underline-from-left:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: 0;
        right: 100%;
        bottom: 0;
        background: $light;
        height: 2px;
        -webkit-transition-property: right;
        transition-property: right;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }
    .hvr-underline-from-left:hover:before,
    .hvr-underline-from-left:focus:before,
    .hvr-underline-from-left:active:before {
        right: 0;
    }
}
